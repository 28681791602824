import { initializeApp } from "firebase/app";
import {
	getFirestore, connectFirestoreEmulator, persistentLocalCache, persistentMultipleTabManager,
	doc, addDoc, updateDoc, setDoc, getDoc, getDocs, collection,
	query, limit, deleteField, serverTimestamp, arrayUnion
} from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { isSupported, getMessaging, getToken, onMessage } from "firebase/messaging";
import { getInstallations, getId, onIdChange } from "firebase/installations";
import { saveAs } from 'file-saver';

const config = {
	apiKey: "AIzaSyC8W0VDPeOnTq6d3d55CsvgFxr4HBZ5baY",
	authDomain: "e-steddfod.firebaseapp.com",
	projectId: "e-steddfod",
	//projectId: "demo-test",
	storageBucket: "e-steddfod.appspot.com",
	messagingSenderId: "634073330231",
	appId: "1:634073330231:web:344806f92cfd89e6fb85b2"
};
const vapidKey = "BN07K3HvEJTz-U1TPJhd7o1aGfi9HcfvqZov_vgjpPmnGAO5F2Mc93csLuREeeWIxaYVjVlBtRl3gjI79BXMqMg";

export function casgliad(name) {
	return collection(firestore, name).withConverter(converter);
}

export function dogfen(colName, id) {
	return doc(firestore, colName, id).withConverter(converter);
}

export async function dataDogfen(docRef) {
	const docSnap = await getDoc(docRef);
	if (docSnap.exists()) return docSnap.data();
	return null;
}

export async function canfodDogfen(colName, ...amodau) {
	const querySnapshot = await getDocs(query(
		casgliad(colName),
		...amodau,
		limit(1)
	));
	if (querySnapshot.empty) return false;
	const docSnap = querySnapshot.docs[0];
	return docSnap;
}

export async function canfodDogfennau(colName, ...amodau) {
	const querySnapshot = await getDocs(query(
		casgliad(colName),
		...amodau
	));
	if (querySnapshot.empty) return false;
	return querySnapshot.docs;
}

export async function cadwCofnod(colName, id, data, creu) {
	if (id) {
		try {
			return await diweddaruCofnod({colName, id, data});
		} catch (error) {
			if (!creu || error.code !== 'not-found') {
				return false;
			}
		}
	}
	return await creuCofnod({colName, id, data})
}

export async function creuCofnod({colName, id, data}) {
	const dataCreu = {}; // gweithio ar gopi
	for (const key of Object.keys(data)) {
		if (gwag(data[key])) {
			continue;
		}
		if (key.includes('.')) {
			let targed = dataCreu;
			const parts = key.split('.');
			const lastPart = parts.pop();
			for (const part of parts) {
				if (!(part in targed)) {
					targed[part] = {};
				}
				targed = targed[part];
			}
			targed[lastPart] = data[key];
		} else {
			dataCreu[key] = data[key];
		}
	}
	dataCreu.meta ??= {};
	dataCreu.meta.crewyd = maesMeta();
	dataCreu.meta.newidiwyd = maesMeta();

	if (id) {
		const docRef = dogfen(colName, id);
		await setDoc(docRef, dataCreu);
		return docRef;
	}
	return await addDoc(casgliad(colName), dataCreu);
}

export async function diweddaruCofnod({colName, id, data, creu}) {
	const dataDiweddaru = {}; // gweithio ar gopi
	for (const key of Object.keys(data)) {
		dataDiweddaru[key] = gwag(data[key]) ? deleteField() : data[key];
	}
	dataDiweddaru['meta.newidiwyd'] = maesMeta();
	const docRef = dogfen(colName, id);
	await updateDoc(docRef, dataDiweddaru);
	return docRef;
}

export function maesMeta() {
	const meta = {
		pryd: serverTimestamp(),
		dull: 'cynhyrchu',
	};
	if (auth?.currentUser?.uid) {
		meta.cyfrif = auth.currentUser.uid;
	}
	return meta;
}

export function gwag(val) {
	return val === undefined || val === null || val === '' || Object.is(NaN, val); // caniatau 0 a false
}

export async function ffeilTocyn(url, params) {
	const token = await auth.currentUser.getIdToken(true);
	const body = new URLSearchParams(params);
	body.set('tocyn', token);
	const response = await fetch(url, {
		credentials: 'omit',
		method: 'POST',
		body,
	});
	if (response.status === 200) {
		let filename = response.headers.get("content-disposition");
		filename = filename?.match(/(?<=")(?:\\.|[^"\\])*(?=")/)?.[0];
		const blob = await response.blob();
		saveAs(blob, filename, {type: response.headers.get('content-type')});
		//application/octet-stream'});
	} else {
		alert('Methwyd lawrlwytho’r ffeil.');
	}
	return;
}

export function cadarnhauHysbysiadau() {
	Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
			console.log('Notification permission granted.');
		}
	});
}

export const app = initializeApp(config, {
	localCache: persistentLocalCache({
		tabManager: persistentMultipleTabManager()
	})
});

export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const converter = {
	toFirestore(data) {
		delete data.id;
		delete data.ref;
		return data;
	},
	fromFirestore(snapshot, options) {
		const data = snapshot.data(options);
		data.id = snapshot.id;
		data.ref = snapshot.ref;
		return data;
	}
};

// onMessage(messaging, (payload) => {
// 	console.log('Message received.', payload);
// });

if (process.env.NODE_ENV !== 'production') {
	connectStorageEmulator(storage, 'localhost', 9199);
	connectFirestoreEmulator(firestore, 'localhost', 8081);
	if (window.location.protocol === 'https') {
		connectAuthEmulator(auth, 'https://6c52-2a01-4b00-d031-a400-e531-7452-e666-8086.ngrok-free.app');
	} else {
		connectAuthEmulator(auth, 'http://localhost:9099');
	}
}

export let messaging = false;
export let deviceId = null;

if (await isSupported()) {

	const installations = getInstallations();
	deviceId = await getId(installations);
	onIdChange(installations, id => { deviceId = id });

	messaging = getMessaging();

	navigator.serviceWorker.register('/cynhyrchu/sw.js', {type: 'module'});
	// .then(sw => {
		// sw.addEventListener('message', event => {
		// 	console.log("Message from service worker", event);
		// 	event.waitUntil(
		// 		sw.showNotification(event.data.title, {
		// 			body: event.data.body
		// 		})
		// 	);
		// });
	// });

	onMessage(messaging, async message => {
		console.log('neges', message);
		if (message.notification) {
			const title = message.notification.title;
			const options = {
				body: message.notification.body,
				image: message.notification.image,
				// icon: '/cynhyrchu/logo512.png'
			};
			const sw = await navigator.serviceWorker.ready;
			sw.showNotification(title, options);
		}
		cofnodiTocyn();
	});

}

export async function tocynNeges() {
	if (!messaging) return false;
	const sw = await navigator.serviceWorker.ready;
	const token = await getToken(messaging, {
		serviceWorkerRegistration: sw,
		vapidKey,
	});
	return token;
}

export async function cofnodiTocyn() {
	const pynciau = ['cymraeg'];
	if (auth?.currentUser?.email.endsWith('@stanno.cymru')) pynciau.push('profi');

	const userId = auth?.currentUser?.uid;
	const tocyn = await tocynNeges();
	if (userId && deviceId && tocyn) {
		const userData = {
			[`dyfeisiau.${deviceId}`]: { tocyn, pryd: serverTimestamp() },
			//pynciau: arrayUnion(...pynciau),
		};
		return cadwCofnod('cyfrifon', userId, userData, true);
	}
}