import { createContext, useState, useEffect, useRef } from 'react';
import { Outlet, useLoaderData } from "react-router-dom";
import { useRolau } from '../lib/ApCefn';
import { gallu } from '../cyffredinol';
import { LlywioBrig, LlywioOchr } from '../display/nav';
import Offcanvas from 'react-bootstrap/Offcanvas';

export const RootContext = createContext();

export async function rootLoader({params}) {
	const blwyddyn = +params.blwyddyn;
	return blwyddyn;
	// return defer({
	// 	blwyddyn: new Promise(resolve => {resolve(2023)})
	// });
}

export function Gwraidd() {
	const blwyddyn = useLoaderData();
	const rolau = useRolau();

	const [show, setShow] = useState(false);

	const [adrannau, setAdrannau] = useState(null);
	const [cystadlaethau, setCystadlaethau] = useState(null);
	const [llefydd, setLlefydd] = useState(null);
	const [prisiau, setPrisiau] = useState(null);
	const storfa = useRef({ listeners:{} });
	const cyntaf = useRef(true);

	useEffect(() => {
		document.documentElement.dataset.bsTheme = ['oren', 'piws', 'gwyrdd', 'pinc', 'glas'][blwyddyn % 5];
		if (!cyntaf.current) {
			const listeners = storfa.current.listeners;
    		return () => {
				for (const unsubscribe in listeners) {
					console.debug(`dadlwytho ${unsubscribe} ${blwyddyn}`);
					listeners[unsubscribe]();
				}
			}
		}
		cyntaf.current = false; // osgoi dadlwytho’r gwrandawyr ar ôl newid y flwyddyn tro gyntaf
	}, [blwyddyn]);

	// if (blwyddyn >= 2024 && !gallu(rolau, 'staff')) {
	// 	return 'Dim mynediad ar hyn o bryd.';
	// }

	const context = {
		blwyddyn, storfa,
		adrannau, setAdrannau,
		cystadlaethau, setCystadlaethau,
		llefydd, setLlefydd,
		prisiau, setPrisiau,
	};

	return (
		<RootContext.Provider value={context}>
			<div className="container-fluid">
				<div className="flex-nowrap flex-column flex-md-row min-vh-100 row">
					<div className="d-none d-md-flex flex-column col-xl-2 col-3 bg-body-secondary d-print-none">
						<Offcanvas show={show} onHide={() => setShow(false)} responsive="md" scroll backdrop className="sticky-top">
							<Offcanvas.Body className="flex-column">
								<LlywioOchr cuddio={() => setShow(false)}/>
							</Offcanvas.Body>
						</Offcanvas>
					</div>
					<div className="col p-0">
						<LlywioBrig dangos={() => setShow(true)} dewislen={show}/>
						<div className="col px-4 py-3">
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</RootContext.Provider>
	);
/*
	return (
		<RootContext.Provider value={context}>
			<div className="container-fluid">
				<div className="flex-nowrap flex-column flex-md-row min-vh-100 row">
					<div className="col-xl-2 col-md-3 col-12 d-flex flex-column bg-body-secondary d-print-none">
						<LlywioOchr />
					</div>
					<div className="col px-4 py-3">
						<Outlet />
					</div>
				</div>
			</div>
		</RootContext.Provider>
	);
*/
}
