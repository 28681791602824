import { cofnodiTocyn } from '../firebase';
import { BellFill, BellSlashFill } from 'react-bootstrap-icons';// import { useToken } from 'react-firebase-hooks/messaging';

export default function BotwmHysbysiadau({className}) {
	const permission = window.Notification?.permission;

	async function arGlic(e) {
		e.preventDefault();
		cofnodiTocyn();
	}

	if (!permission) return;

	if (permission === 'granted') {
		return <BellFill className={className}/>;
	}

	return <span role="button" className={className} onClick={arGlic}><BellSlashFill/></span>;
};
