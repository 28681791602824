import { useLingui } from "@lingui/react"
import { dynamicActivate } from '../lib/ApCefn';

function NewidIaith({ className }) {
    const {i18n} = useLingui();
	return (
	<div className={ 'btn-group btn-group-sm' + (className ? ' ' + className : '') } role="group">
		<button type="button" onClick={() => dynamicActivate('cy')} className={ i18n.locale === 'en' ? 'btn btn-outline-primary' : 'btn btn-primary' }>Cymraeg</button>
		<button type="button" onClick={() => dynamicActivate('en')} className={ i18n.locale === 'en' ? 'btn btn-primary' : 'btn btn-outline-primary' }>English</button>
	</div>
	);
}

export default NewidIaith;