import { auth } from '../firebase';

import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useNavigation, useLocation } from 'react-router-dom';
// import Logo from '../media/eisteddfod.svg';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import {
	Image, PiggyBank, List, ChevronDoubleLeft, Stars, QrCodeScan, CalendarWeek,
	PersonSquare, Trophy, ViewList,	Bank, CardText, TicketPerforated, BoxArrowRight,
	BoxArrowUpRight, FileEarmarkBarGraph, PatchQuestionFill, CarFrontFill, ArrowRepeat
} from 'react-bootstrap-icons';
import { useCyfrif, useBlwyddyn, AppContext } from '../lib/ApCefn';
import Dropdown from 'react-bootstrap/Dropdown';
import Prysur from '../darnau/Prysur';
import NewidIaith from '../darnau/NewidIaith';
// import { dangosDyddiad } from '../cyffredinol';
import { Trans } from '@lingui/macro'
import { gallu } from '../cyffredinol';
import Button from 'react-bootstrap/Button';
import BotwmHysbysiadau from './BotwmHysbysiadau';

function Logo() {
	return (
		<svg alt="Eisteddfod" className="d-block text-primary" width="100" height="17" viewBox="0 0 100 17" xmlns="http://www.w3.org/2000/svg"><path d="m4.12 4.05.05 1.61h3.87l.03 5.19-4.18.06-.04 1.37 4.22-.15-.02 3.85s-7.94.4-7.84.1c.1-.3-.2-15.57-.2-15.57l8.12-.41-.02 3.8zm6.39-3.95-.1 16.27 4.37-.1.5-15.88zm14.38.1s-5.35-.6-6.55 2.28c-1.19 2.88 2.48 5.65 2.28 8.43s-3.97 2.18-3.97 2.18l-.2 3.17s5.95.6 7.83-2.38.69-5.95-1.09-7.24c-1.79-1.29.1-3.17 1.98-3.18l-.3-3.27zm16.47 11.7-.04-1.68 4.61.41v-5.62l-4.73.2-.03-1.05 4.76-.44v-3.62l-8.33.1v16.16l8.33-.1v-4.18l-4.56-.18zm6.95-11.7v16.06s3.27 0 5.55 0 3.77-3.47 3.57-8.83c-.2-5.35-2.08-7.84-9.12-7.24zm42.55.1v16.06s3.27 0 5.55 0 3.77-3.47 3.57-8.83-2.09-7.84-9.12-7.24zm-31.74 0v16.06s3.77 0 6.35 0 2.34-8.64 1.79-11.9c-.8-4.66-6.06-4.56-8.14-4.16zm18.68 5.77-3.91.38v-1.09l3.87-.76-.1-4.2-7.94.1-.4 16.27 4.14-.05-.09-5.83 4.52-.63-.1-4.2zm6.61-5.57c-4.36.1-5.06 1.13-4.66 8.75.39 7.62 2.48 7.82 4.76 7.62 2.28-.21 3.27-.93 3.57-2.88.3-1.96.4-8.34.1-10.5s-2.06-3.02-3.77-2.98zm-48.9 3.96-.1-4.16-8.13.2-.1 4.07 2.68-.03v11.73l3.67-.2.17-11.58 1.81-.02z" fill="currentColor"/></svg>
	);
}

export function LlywioBrig({dangos, dewislen}) {
	const blwyddyn = useBlwyddyn();
	return (
		<div className="d-md-none d-flex px-4 py-3 align-items-center bg-body-secondary d-print-none">
			<Link to={`/${blwyddyn}`} className="me-1">
				<Logo/>
				{/* <img src={logo} alt="Eisteddfod" width="100" height="17" className="d-block"/> */}
			</Link>
			<Button size="sm" onClick={dangos} className="ms-auto" disabled={dewislen} variant={dewislen ? 'secondary' : 'outline-primary'}>
				<List/>
				<span><Trans>Dewislen</Trans></span>
			</Button>
		</div>
	);
}

export function LlywioOchr({cuddio}) {
	const navigation = useNavigation();
	const navigate = useNavigate();
	//const ap = useAp();
	const {ap, fersiwn, rolau, setRolau} = useContext(AppContext);
	const cyfrif = useCyfrif();
	const location = useLocation();
	const [blwyddyn, setBlwyddyn] = useState(null);
	const [active, setActive] = useState(null);
	const steddfod = true; // wrthi’n digwydd ai peidio
	const stanno = cyfrif?.email.endsWith('@stanno.cymru') || false;

	useEffect(() => {
		const paths = location?.pathname.split('/', 3);
		const blwyddyn = paths[1]?.match(/^\d{4}$/) ? +paths[1] : (new Date()).getUTCFullYear();
		const adran = blwyddyn ? paths[2] : paths[1];
		const gwraidd = '/' + (blwyddyn ? blwyddyn + '/' : '');
		const active = gwraidd + (adran === 'slot' ? 'amserlen' : adran);
		setBlwyddyn(blwyddyn);
		setActive(active);
	}, [location]);

	function handleLogout(e) {
		e.preventDefault();
		auth.signOut().then(() => {
			navigate("/mewngofnodi");
		});
	}

	function newidRolau(e) {
		if (stanno) {
			const rolauNewydd = e.target.value.split(/\W+/).sort();
			if (rolauNewydd.join(',') !== rolau.sort().join(',')) {
				setRolau(rolauNewydd);
			}
		}
	}

/*
	const nav = [
		{
			eicon: <CalendarWeek/>,
			enw: <Trans>Amserlen</Trans>,
			dolen: `/${blwyddyn}/pobl`,
		},
		{
			eicon: <PersonSquare/>,
			enw: <Trans>Artistiaid</Trans>,
			dolen: `/${blwyddyn}/pobl`,
		},
		{
			eicon: <Bank/>,
			enw: <Trans>Llwyfannau</Trans>,
			dolen: `/${blwyddyn}/llefydd`,
			rol: 'staff,'
		},
		{
			eicon: <Stars/>,
			enw: <Trans>Canlyniadau</Trans>,
			dolen: `/${blwyddyn}/canlyniadau`,
		},
		{
			eicon: <Trophy/>,
			enw: <Trans>Cystadlaethau</Trans>,
			dolen: `/${blwyddyn}/cystadlaethau`,
			dolenni: [
				{
					enw: <Trans>Adrannau</Trans>,
					dolen: `/${blwyddyn}/adrannau`,
					rol: 'staff,'
				},
				{
					enw: <Trans>Darnau</Trans>,
					dolen: `/${blwyddyn}/darnau`,
					rol: 'staff,'
				},
				{
					enw: <Trans>Beirniadaeth</Trans>,
					dolen: `/${blwyddyn}/darnau`,
					rol: 'staff,'
				},
			]
		},
		{
			eicon: <PersonSquare/>,
			enw: <Trans>Artistiaid</Trans>,
			dolen: `/${blwyddyn}/pobl`,
			rol: 'staff,'
		},
	];
*/
	if (active === null) return;

	return (
		<Nav variant="pills" defaultActiveKey={active} className="flex-column sticky-top py-3 min-vh-100">
			<Nav.Item className="nav-text mb-2 d-flex align-items-center">
				<Link to={`/${blwyddyn}`} className="pe-1 me-auto">
					{/* <img src={logo} alt="Eisteddfod" width="100" height="17" className="d-block"/> */}
					<Logo/>
				</Link>
				<DewisBlwyddyn dewiswyd={blwyddyn}/>
				<Button size="sm" className="d-md-none ms-auto" onClick={cuddio}>
					<ChevronDoubleLeft/>
					<span><Trans>Cau</Trans></span>
				</Button>
			</Nav.Item>
			<Nav.Item>
				<LinkContainer to={ `/${blwyddyn}/amserlen` }><Nav.Link>
					<CalendarWeek/> <span><Trans>Amserlen</Trans></span>
				</Nav.Link></LinkContainer>
			</Nav.Item>
			{ rolau?.includes('staff') &&
				<>
				<Nav.Item>
					<LinkContainer to={ `/${blwyddyn}/pobl` }><Nav.Link>
						<PersonSquare/> <span><Trans>Artistiaid</Trans></span>
					</Nav.Link></LinkContainer>
				</Nav.Item>
				</>
				/* <LinkContainer to={ `/${blwyddyn}/adrannau` }><Nav.Link>
					<ViewList/> <span><Trans>Adrannau</Trans></span>
				</Nav.Link></LinkContainer> */
			}
			{ steddfod &&
				<Nav.Item>
					<LinkContainer to={ `/${blwyddyn}/canlyniadau` }><Nav.Link>
						<Stars/> <span><Trans>Canlyniadau</Trans></span>
					</Nav.Link></LinkContainer>
				</Nav.Item>
			}
			<Nav.Item>
				<LinkContainer to={ `/${blwyddyn}/cystadlaethau` }><Nav.Link>
					<Trophy/> <span><Trans>Cystadlaethau</Trans></span>
				</Nav.Link></LinkContainer>
				{ rolau.includes('staff') && <>
					<Nav.Item>
						<LinkContainer to={ `/${blwyddyn}/adrannau` }><Nav.Link>
							<Trans>Adrannau</Trans>
						</Nav.Link></LinkContainer>
						<LinkContainer to={ `/${blwyddyn}/darnau` }><Nav.Link>
							<Trans>Darnau</Trans>
						</Nav.Link></LinkContainer>
						{ /* steddfod && gallu(rolau, ['cystadlu']) &&
							<Nav.Item>
								<a href="/pencefn/beirniadaeth.php" className="nav-link" target="sganio">
									<QrCodeScan/> <span><Trans>Beirniadaeth</Trans></span>
								</a>
							</Nav.Item>
						*/ }
						{ steddfod && gallu(rolau, ['cystadlu']) &&
							<LinkContainer to={ `/${blwyddyn}/beirniadaeth` }><Nav.Link>
								<QrCodeScan/> <span><Trans>Beirniadaeth</Trans></span>
							</Nav.Link></LinkContainer>
						}
					</Nav.Item>
				</> }
			</Nav.Item>
			<Nav.Item>
				<LinkContainer to={ `/${blwyddyn}/llefydd` }><Nav.Link>
					<Bank/> <span><Trans>Llwyfannau</Trans></span>
				</Nav.Link></LinkContainer>
			</Nav.Item>
			{ gallu(rolau, ['staff']) && <>
				<Nav.Item>
					<LinkContainer to={ `/${blwyddyn}/tudalennau` }><Nav.Link>
						<CardText/> <span><Trans>Tudalennau</Trans></span>
					</Nav.Link></LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to={ `/${blwyddyn}/adnoddau` }><Nav.Link>
						<Image/> <span><Trans>Adnoddau</Trans></span>
					</Nav.Link></LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to={ `/${blwyddyn}/tocynnau` }><Nav.Link>
						<TicketPerforated/> <span><Trans>Tocynnau</Trans></span>
					</Nav.Link></LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to={ `/${blwyddyn}/cerbydau` }><Nav.Link>
						<CarFrontFill/> <span><Trans>Cerbydau</Trans></span>
					</Nav.Link></LinkContainer>
				</Nav.Item>
			</> }
			{ gallu(rolau, ['cyllid']) && <>
				<Nav.Item>
					<LinkContainer to={ `/${blwyddyn}/taliadau` }><Nav.Link>
						<PiggyBank/> <span><Trans>Taliadau</Trans></span>
					</Nav.Link></LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to={ `/${blwyddyn}/adroddiadau` }><Nav.Link>
						<FileEarmarkBarGraph/> <span><Trans>Adroddiadau</Trans></span>
					</Nav.Link></LinkContainer>
				</Nav.Item>
			</> }
			<Nav.Item>
				<LinkContainer to={ `/${blwyddyn}/cymorth` }><Nav.Link>
					<PatchQuestionFill/> <span><Trans>Cymorth</Trans></span>
				</Nav.Link></LinkContainer>
			</Nav.Item>
			{ gallu(rolau, ['artist', 'cystadleuydd', 'beirniad', 'cyfeilydd', 'trefnydd']) &&
				<Nav.Item><Nav.Link href={
					process.env.NODE_ENV === 'development'
						? '//lleol.artistiaid-eisteddfod.cymru:3001/cofrestru'
						: '/cofrestru'
					} target="cofrestru">
					<BoxArrowUpRight/>
					<span><Trans>Cofrestru</Trans></span>
				</Nav.Link></Nav.Item>
			}
			<div className="nav-text mt-auto">
				{ navigation.state === 'loading' && <Prysur size="sm" align="left"/> }
			</div>
			<div className="nav-text">
				<small>
					<small className="text-secondary">
						<Trans>Fersiwn {fersiwn?.current}</Trans>
						{ (ap?.fersiwnCefn !== fersiwn?.current) &&
							<span className="ms-1 badge text-bg-warning" onClick={e => window.location.reload()}><ArrowRepeat/> Diweddaru</span>
						}
					</small>
					<div title={rolau?.join(', ')}>
						{cyfrif?.displayName}
						{stanno && <BotwmHysbysiadau className="ms-1"/>}
					</div>
				</small>
			</div>
			{ stanno &&
				<input type="text" onBlur={newidRolau} defaultValue={rolau.join(',')} className="form-control form-control-sm mt-1"/>
			}
			<Nav.Item>
				<Nav.Link onClick={handleLogout}>
					<BoxArrowRight/>
					<span><Trans>Gadael</Trans></span>
				</Nav.Link>
			</Nav.Item>
			<Nav.Item className="nav-text py-1">
				<NewidIaith/>
			</Nav.Item>
		</Nav>
	);
// title={ dangosDyddiad(ap?.dyddiadCefn?.toDate(), 'EEE d MMM yyyy HH:mm') }
}

function DewisBlwyddyn({dewiswyd}) {
	const location = useLocation();
	const blynyddoedd = [2023, 2024, 2025, 2026];
	const match = location.pathname.match(/^\/\d{4}(\/[^/]+)/);
	const path = match ? match[1] : '';
	return (
		<Dropdown>
			<Dropdown.Toggle variant="outline-primary" size="sm" className="btn-vsm">
				{dewiswyd}
			</Dropdown.Toggle>
			<Dropdown.Menu>
			{ blynyddoedd.map( blwyddyn => {
				const hwn = blwyddyn === dewiswyd;
				return (
					<LinkContainer to={'/' + blwyddyn + path} key={blwyddyn}>
						<Dropdown.Item active={hwn} aria-current={hwn}>
							{blwyddyn}
						</Dropdown.Item>
					</LinkContainer>
				);
			}) }
			</Dropdown.Menu>
		</Dropdown>
	);
}

