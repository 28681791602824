import { useIdToken } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { auth, dogfen, casgliad, cofnodiTocyn, tocynNeges, deviceId, cadwCofnod } from '../firebase';
import { query, where, orderBy, onSnapshot, serverTimestamp, getDoc, arrayUnion } from "firebase/firestore";

import { useState, createContext, useContext, useEffect, useRef } from 'react';
import { useRouteLoaderData, useNavigate, useLocation } from 'react-router-dom';
import { RootContext} from '../routes/root';
import Prysur from '../darnau/Prysur';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

export const AppContext = createContext();

export const locales = {
	cy: "Cymraeg",
	en: "English",
};
export const defaultLocale = "cy";

export async function dynamicActivate(locale) {
	if (!locale) {
		try {
			locale = localStorage.getItem('iaith');
		} catch (e) { }
	}
	locale = locales[locale] ? locale : defaultLocale;
	const {messages} = await import(`../locales/${locale}/messages`);
	i18n.load(locale, messages);
	i18n.activate(locale);
	document.documentElement.lang = locale;
	try {
		localStorage.setItem('iaith', locale);
	} catch (e) { }
	return locale;
}

export function ApCefn({children}) {

	const fersiwn = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const [ap, apLoading] = useDocumentData( dogfen('cyffredinol', 'ap') );
	const [rolau, setRolau] = useState([]);
	const [locale, setLocale] = useState(() => {
console.debug('gosod iaith', location?.state?.lang);
		return dynamicActivate(location?.state?.lang);
	});
	// const [hysbysiadau, setHysbysiadau] = useState([]);
	// const lang = location?.state?.lang;

	useEffect(() => {
		if (!apLoading && ap) fersiwn.current = ap.fersiwnCefn;
	}, [apLoading]);

	useEffect(() => {
		if (ap?.fersiwnCefn && fersiwn.current !== ap.fersiwnCefn) {
			window.location.reload();
		}
	}, [location]);

	const [user, userLoading] = useIdToken(auth, {onUserChanged: async user => {
		const token = await user.getIdTokenResult();
		if (!token.claims?.cynhyrchu) {
			setRolau(false);
			return;
		}
		const rolau = typeof token.claims?.rolau === 'string'
			? [token.claims.rolau]
			: ( token.claims?.rolau || [] );
		const userData = {
			ebost: user.email,
			// mewngofnod: serverTimestamp(),
		};
		if (window.Notification?.permission === 'granted') {
			// cofnodiTocyn();
			const tocyn = await tocynNeges();
			if (tocyn) {
				userData[`dyfeisiau.${deviceId}`] = { tocyn, pryd: serverTimestamp() };
			}
		}
		const gwirioAmser = false;
		if (gwirioAmser) {
			const docRef = await cadwCofnod('cyfrifon', user.uid, userData, true);
			const docSnap = await getDoc(docRef);
			const secondsOut = 600; // 10 min
			const serverEpoch = docSnap.get('meta.newidiwyd.pryd')?.seconds;
			const localEpoch = Math.round(Date.now()/1000);
	// console.debug(serverEpoch, localEpoch, serverEpoch - localEpoch, secondsOut);
			if (localEpoch < serverEpoch - secondsOut || localEpoch > serverEpoch + secondsOut) {
				alert("Mae cloc dy gyfriadur yn anghywir. Fydd di ddim yn gallu newid unrhyw beth tan i ti gywiro hyn. Hola i Iwan os am gymorth.");
				setRolau(['darllen']);
				return;
			}
		} else {
			const offset = +(new Date()).getTimezoneOffset();
			if ( ![0, -60].includes(offset) && ( rolau.includes('staff') || rolau.includes('bbc') ) ) {
				alert("Mae cloc dy gyfrifiadur yn anghywir. Fydd di ddim yn gallu newid unrhyw beth tan i ti gywiro hyn. Hola i Iwan os am gymorth.");
				setRolau(['darllen']);
				return;
			}
		}
		setRolau(rolau);
	}});

	if (apLoading || userLoading) {
		return (
			<div className={'position-absolute top-50 start-50 translate-middle'}>
				<Prysur/>
			</div>
		);
	}

	if (!user || rolau === false) {
		navigate('/mewngofnodi');
	}

	// const ref = useRef({rolau:[], hysbysiadau, setHysbysiadau});
	// ref.current.creuHysbysiad = function(testun, math) {
	// 	ref.current.hysbysiadau = [...hysbysiadau, {testun, math, key:+new Date()}];
	// 	setHysbysiadau(ref.current.hysbysiadau);
	// }

	// function mapORestr(rhestr) {
	// 	return Object.fromEntries( rhestr.map(eitem => [eitem.id, eitem.enw]) );
	// }
	//return <Root/>;
	const context = {user, rolau, setRolau, mathauSlot, mathauCystadlu, swyddogaethau, ap, fersiwn};
	return (
		<AppContext.Provider value={context}>
			<I18nProvider i18n={i18n}>
				{children}
			</I18nProvider>
		</AppContext.Provider>
	);

}

export function useRolau(gwirio) {
	const {rolau} = useContext(AppContext);
	if (gwirio) {
		return rolau && (rolau.includes(gwirio) || rolau.includes('gweinyddu'));
	}
	return rolau || [];
}

export function useGolygu(dangos) {
	const {rolau} = useContext(AppContext);
	if (rolau?.includes('gweinyddu')) return true;

	let angenRol;
	if (dangos === 'cyhoeddus') {
		angenRol = 'cyhoeddi';
	} else if (dangos === 'golygwyd') {
		angenRol = 'golygydd';
	} else {
		angenRol = 'staff';
	}
	return rolau?.includes(angenRol) || false;
}

export function useAp() {
	const {ap} = useContext(AppContext);
	return ap || null;
}

export function useCyfrif() {
	const {user} = useContext(AppContext);
	return user || null;
}

export function useBlwyddyn() {
	const blwyddyn = useRouteLoaderData('blwyddyn');
	return blwyddyn || 2099;
}

export function useAdrannau() {
	const { blwyddyn } = useContext(RootContext);
	return useCasgliad('adrannau', [
		casgliad(`${blwyddyn}/cynhyrchu/adrannau`),
		orderBy('trefn', 'asc')
	]);
}

export function useCystadlaethau() {
	const { blwyddyn } = useContext(RootContext);
	return useCasgliad('cystadlaethau', [
		casgliad(`${blwyddyn}/cynhyrchu/cystadlaethau`),
		//where('dangos', 'in', ['barod', 'golygwyd', 'cyhoeddus']),
		orderBy('rhif', 'asc')
	]);
}

export function useLlefydd() {
	const { blwyddyn } = useContext(RootContext);
	return useCasgliad('llefydd', [
		casgliad(`${blwyddyn}/cynhyrchu/llefydd`),
		where('dangos', 'in', ['cuddio', 'barod', 'golygwyd', 'cyhoeddus']),
		orderBy('trefn', 'asc')
	]);
}

export function usePrisiau() {
	const { blwyddyn } = useContext(RootContext);
	return useCasgliad('prisiau', [
		casgliad(`${blwyddyn}/preifat/prisiau`),
		orderBy('enw', 'asc')
	]);
}

function useCasgliad(enw, amodau) {
	const context = useContext(RootContext);

	if (context[enw] === null) {
		const setData = context['set' + enw.charAt(0).toUpperCase() + enw.slice(1)];
		context[enw] = false;//setData(false); // osgoi ail-lwytho

		const unsubscribe = onSnapshot(query(...amodau), docs => {
			const data = [];
			docs.forEach(doc => data.push(doc.data()));
			setData(data);
			// console.debug(`Llwythwyd ${enw}`, data);
		}, error => {
			console.debug(`Methwyd llwytho ${enw}`, error);
		});
		context.storfa.current.listeners[enw] = () => {
			unsubscribe();
			setData(null);
		};
	}
	return context[enw] || [];
}

export const mathauSlot = [
	{id: "cabaret", enw: "Cwt Cabaret", enw_en: "Cabaret"},
	{id: "celf", enw: "Celf a chrefft", enw_en: "Arts and crafts"},
	{id: "cerdd", enw: "Cerddoriaeth", enw_en: "Music"},
	{id: "chwaraeon", enw: "Chwaraeon a hamdden", enw_en: "Sports and leisure"},
	{id: "comedi", enw: "Comedi", enw_en: "Comedy"},
	{id: "cyngerdd", enw: "Cyngherddau", enw_en: "Concerts"},
	{id: "dawns", enw: "Dawns", enw_en: "Dance"},
	{id: "drama", enw: "Drama", enw_en: "Drama"},
	{id: "dysgu", enw: "Dysgu Cymraeg", enw_en: "Learning Welsh"},
	{id: "llen", enw: "Llenyddiaeth", enw_en: "Literature"},
	{id: "mas", enw: "Mas ar y Maes", enw_en: "Mas ar y Maes"},
	{id: "plant", enw: "Plant a theulu", enw_en: "Family and children"},
	{id: "seremoni", enw: "Seremonïau", enw_en: "Ceremonies"},
	{id: "sgwrs", enw: "Sgyrsiau, gweithdai, cyfarfodydd", enw_en: "Talks, workshops, meetings"},
	{id: "syrcas", enw: "Theatr stryd a syrcas", enw_en: "Street theatre and circus"},
];

export const mathauCystadlu = [
	{id:"ymarfer", enw:"Ymarfer", enw_en:"Rehearsal"},
	{id:"rhagwrandawiad", enw:"Rhagwrandawiad", enw_en:"Pre-hearing"},
	{id:"cynderfynol", enw:"Rownd cynderfynol", enw_en:"Semi final"},
	{id:"perfformiad", enw:"Perfformiad", enw_en:"Performance"},
	{id:"cystadlu", enw:"Rownd derfynol", enw_en:"Final"},
	{id:"beirniadaeth", enw:"Beirniadaeth", enw_en:"Adjudication"},
	{id:"canlyniad", enw:"Canlyniad", enw_en:"Result"},
];

export const swyddogaethau = [
	{id:"artist", enw:"Artist"},
	{id:"arweinydd", enw:"Arweinydd"},
	{id:"beirniad", enw:"Beirniad"},
	{id:"cyfeilydd", enw:"Cyfeilydd"},
	{id:"datgeinydd", enw:"Datgeinydd"},
	{id:"trefnydd", enw:"Trefnydd"},
	{id:"detholwr", enw:"Detholwr"},
	{id:"gweinyddwr", enw:"Gweinyddwr"},
	{id:"cyfrannwr", enw:"Cyfrannwr"},
];

// export const gwelededdCofnod = {
// 	dileu: "Dileu",
// 	cuddio: "Cuddio",
// 	drafft: "Drafft",
// 	barod: "Barod",
// 	golygwyd: "Golygwyd",
// 	cyhoeddus: "Cyhoeddus",
// };
