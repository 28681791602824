import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
// import * as Sentry from "@sentry/react";
import { ApCefn } from "./lib/ApCefn";
import { Gwraidd, rootLoader } from './routes/root';

import ErrorPage from "./error";

if (process.env.NODE_ENV === 'production') {
	console.debug = () => {}
}

// let sentryCreateBrowserRouter;
// if (process.env.NODE_ENV === 'production') {
// 	console.debug = () => {}
// 	Sentry.init({
// 		dsn: "https://ae50e46eca71e17a3ee0b3557450f3b6@o4507382895345664.ingest.de.sentry.io/4507382903210064",
// 		environment: process.env.NODE_ENV,
// 		// integrations: [
// 		// 	Sentry.browserTracingIntegration(), // angen newid
// 		// 	Sentry.replayIntegration(),
// 		// ],
// 		// tracesSampleRate: 1.0, //  Capture 100% of the transactions
// 		// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
// 		// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 		// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// 	});
// 	sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
// } else {
// 	sentryCreateBrowserRouter = createBrowserRouter;
// }
// const router = sentryCreateBrowserRouter([

const router = createBrowserRouter([
	{
		index: true,
		element: <Navigate to={`/${(new Date()).getUTCFullYear()}/amserlen`} replace={true}/>,
	},
	{
		path: "mewngofnodi",
		lazy: () => import("./routes/mewngofnodi"),
		errorElement: <ErrorPage />,
	},
	{
		path: ":blwyddyn",
		id: "blwyddyn",
		element: <ApCefn><Gwraidd/></ApCefn>,
		loader: rootLoader, // ({params}) => +params.blwyddyn,
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				element: <Navigate to="amserlen" replace={true}/>,
			},
			{
				path: "adrannau",
				children: [
					{
						index: true,
						async lazy() {
							const { Component } = await import("./routes/adrannau");
							return { Component };
						},
					},
					{
						path: ":id",
						async lazy() {
							const { loader, Adran } = await import("./routes/adrannau");
							return { loader, Component: Adran };
						},
					},
					{
						path: ":id/golygu",
						async lazy() {
							const { action, loader, GolyguAdran } = await import("./routes/adrannau");
							return { action, loader, Component: GolyguAdran };
						},
					},
				]
			},
			{
				path: "amserlen",
				children: [
					{
						path: ":llwyfan?/:dydd?",
						lazy: () => import("./routes/amserlen"),
					},
				],
			},
			{
				path: "slot",
				children: [
					// {
					// 	path: ":id",
					// 	async lazy() {
					// 		const { loader, Slot } = await import("./routes/slotiau");
					// 		return { loader, Component: Slot }
					// 	},
					// },
					{
						path: ":id/:gweithred?", //":id?/:gweithred",
						async lazy() {
							const { action, loader, GolyguSlot } = await import("./routes/slotiau");
							return { action, loader, Component: GolyguSlot }
						},
					},
				],
			},
			{
				path: "ceisiadau",
				children: [
					{
						path: ":id",
						async lazy() {
							const { loader, Cais } = await import("./routes/cais");
							return { loader, Component: Cais }
						},
					},
					{
						path: ":id?/golygu",
						async lazy() {
							const { action, loader, GolyguCais } = await import("./routes/cais");
							return { action, loader, Component: GolyguCais }
						},
					},
				],
			},
			{
				path: "cystadlaethau",
				children: [
					{
						index: true,
						async lazy() {
							const { Mynegai } = await import("./routes/cystadlaethau");
							return { Component: Mynegai }
						},
					},
					{
						path: ":id",
						async lazy() {
							const { loader, Cystadleuaeth } = await import("./routes/cystadlaethau");
							return { loader, Component: Cystadleuaeth }
						},
					},
					{
						path: ":id/golygu",
						async lazy() {
							const { action, loader, GolyguCystadleuaeth } = await import("./routes/cystadlaethau");
							return { action, loader, Component: GolyguCystadleuaeth }
						},
					},
				],
			},
			{
				path: "adnoddau",
				children: [
					{
						index: true,
						async lazy() {
							const { Mynegai } = await import("./routes/adnoddau");
							return { Component: Mynegai };
						},
					},
					{
						path: ":id?/golygu",
						async lazy() {
							const { action, loader, Golygu } = await import("./routes/adnoddau");
							return { action, loader, Component: Golygu };
						},
					},
				]
			},
			{
				path: "darnau",
				children: [
					{
						index: true,
						async lazy() {
							const { Mynegai } = await import("./routes/darnau");
							return { Component: Mynegai };
						},
					},
					{
						path: ":id?/golygu",
						async lazy() {
							const { action, loader, Golygu } = await import("./routes/darnau");
							return { action, loader, Component: Golygu };
						},
					},
				]
			},

			{
				path: "llefydd",
				children: [
					{
						index: true,
						async lazy() {
							const { loader, Mynegai } = await import("./routes/llefydd");
							return { loader, Component: Mynegai };
						},
					},
					{
						path: ":id?/golygu",
						async lazy() {
							const { action, loader, Golygu } = await import("./routes/llefydd");
							return { action, loader, Component: Golygu };
						},
					},
				]
			},
			{
				path: "perfformiadau",
				children: [
					{
						path: ":id?/golygu",
						async lazy() {
							const { action, loader, Golygu } = await import("./routes/perfformiadau");
							return { action, loader, Component: Golygu }
						},
					},
				],
			},
			{
				path: "pobl",
				children: [
					{
						index: true,
						async lazy() {
							const { Mynegai } = await import("./routes/pobl");
							return { Component: Mynegai };
						},
					},
					{
						path: ":id",
						async lazy() {
							const { loader, Person } = await import("./routes/pobl");
							return { loader, Component: Person };
						},
					},
					{
						path: ":id?/golygu",
						async lazy() {
							const { action, loader, GolyguPerson } = await import("./routes/pobl");
							return {  action, loader, Component: GolyguPerson };
						},
					},
				],
			},
			{
				path: "tudalennau",
				children: [
					{
						index: true,
						async lazy() {
							const { Mynegai } = await import("./routes/tudalennau");
							return { Component: Mynegai };
						},
					},
					{
						path: ":id?/golygu",
						async lazy() {
							const { action, loader, Golygu } = await import("./routes/tudalennau");
							return { action, loader, Component: Golygu };
						},
					},
				]
			},
			{
				path: "cymorth",
				children: [
					{
						index: true,
						async lazy() {
							const { Mynegai } = await import("./routes/cymorth");
							return { Component: Mynegai };
						},
					},
					{
						path: ":id",
						async lazy() {
							const { loader, Dangos } = await import("./routes/cymorth");
							return { loader, Component: Dangos };
						},
					},
					{
						path: ":id/golygu",
						async lazy() {
							const { action, loader, Golygu } = await import("./routes/cymorth");
							return { action, loader, Component: Golygu };
						},
					},
				]
			},
			// {
			// 	path: "cerbydau/:dydd?",
			// 	lazy: () => import("./routes/cerbydau"),
			// },
			{
				path: "cerbydau/:gweithred?/:id?",
				lazy: () => import("./routes/cerbydau"),
			},
			{
				path: "tocynnau/:gweithred?/:id?",
				lazy: () => import("./routes/tocynnau"),
			},
			{
				path: "canlyniadau/:dydd?",
				lazy: () => import("./routes/canlyniadau"),
			},
			{
				path: "taliadau/:dydd?",
				lazy: () => import("./routes/taliadau"),
			},
			{
				path: "adroddiadau",
				lazy: () => import("./routes/adroddiadau"),
			},
			{
				path: "beirniadaeth",
				lazy: () => import("./routes/beirniadaeth"),
			},
			{
				path: '*',
				element: <div>Does dim byd yma 😕</div>,
			},
		],
	}
], {
	basename: '/cynhyrchu',
	future: {
		v7_normalizeFormMethod: true,
	}
});

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
