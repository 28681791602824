import Spinner from 'react-bootstrap/Spinner';
// import { Trans } from '@lingui/macro'

export default function Prysur({size, align}) {
// h-100'
	const cls = 'd-flex'
		+ (size === 'sm' ? ' my-2' : ' my-3')
		+ (align === 'left' ? '' : ' align-content-center justify-content-center');

	return (
		<div className={cls}>
			<Spinner animation="border" variant="secondary" role="status" size={size}>
				<span className="visually-hidden">Llwytho…</span>
			</Spinner>
		</div>
	);
	// return (
	// 	<Spinner animation="border" variant="secondary" role="status">
	// 		<span className="visually-hidden"><Trans>Llwytho…</Trans></span>
	// 	</Spinner>
	// );
}
