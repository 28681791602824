import { Timestamp, documentId, where, doc, getDoc } from 'firebase/firestore';
import { canfodDogfen, firestore } from './firebase';
import { formatInTimeZone, toDate } from 'date-fns-tz';
import { add, getDay, addDays, parseISO } from 'date-fns';
import { cy, enGB } from 'date-fns/locale';
import { unflatten } from 'flat';
import { useLingui } from "@lingui/react";
//const locales = {cy};

export function EnwId({rhestr, id}) {
	const {i18n} = useLingui();
	for (const rhes of rhestr) {
		if (rhes.id === id) {
			if (i18n.locale === 'en' && rhes.enw_en) return rhes.enw_en;
			return rhes.enw;
		}
	}
}

export function eitemRhestr(rhestr, id) {
	for (const rhes of rhestr) {
		if (rhes.id === id) return rhes;
	}
}

export function dileuEitemRhestr(rhestr, id) {
	return rhestr.filter(eitem => eitem.id !== id);
}

export function trefnuPobl(data, pobl) {
	return data?.sort((a,b) => {
		return (pobl[a.id].trefn ?? 0) - (pobl[b.id].trefn ?? 0) || ( a.enw < b.enw ? -1 : ( a.enw > b.enw ? 1 : 0));
	})
}

export function mapRhestr(data) {
	const map = {};
	for(const eitem of data) {
		map[eitem.id] = data;
	}
	return map;
}

export function rhestrMap(data) {
	if (data.constructor.name === 'Object') {
		return Object.entries(data).map(a => {
			return {id: a[0], enw: a[1]}
		});
	}
	return data || [];
}

// object -> array gyda threfn
export function rhestrData(data) {
	return Object.entries(data).map(([id, val]) => {
		return {id, ...val};
	}).sort((a, b) =>
		((a.trefn ?? 0) - (b.trefn ?? 0)) || (a.enw < b.enw ? -1 : (a.enw > b.enw ? 1 : 0))
	);
}

// array -> object gyda threfn
export function dataRhestr(eitemau, meysydd = ['enw']) {
	const data = {};
	let trefn = 0;
	for (const eitem of eitemau) {
		const copi = Object.keys(eitem).filter(key => meysydd.includes(key)).reduce((obj, key) => {
    		obj[key] = eitem[key];
    		return obj;
		}, {});
		copi.trefn = trefn++;
		data[eitem.id] = copi;
	}
	return data;
}

export function Dyddiad({dyddiad, fformat}) {
	const {i18n} = useLingui();
	if (isNaN(dyddiad)) return '';

	fformat ??= 'd MMM yyyy HH:mm';
	const locale = i18n.locale === 'cy' ? cy : enGB;
	const testun = formatInTimeZone(dyddiad, 'Europe/London', fformat, {locale})

	if (i18n.locale === 'cy' && fformat.includes('EEEE')) {
		return testun.replace('dydd ', '');
	}
	return testun;
}

export function dangosDyddiad(dyddiad, fformat, iaith) {
	const testun = formatInTimeZone(dyddiad || new Date(), 'Europe/London', fformat, { locale: iaith === 'en' ? enGB : cy });
	if (iaith === 'cy' && fformat.includes('EEEE')) {
		return testun.replace('dydd ', '');
	}
	return testun;
}

// string -> JS
export function dyddiadJS(dyddiad = '') {
	return toDate(parseISO(dyddiad), {timeZone: 'Europe/London'});
}

// string -> Firestore
export function dyddiadFS(dyddiad) {
	return Timestamp.fromDate( toDate(dyddiad, {timeZone: 'Europe/London'}) );
	// return Timestamp.fromDate(fromZonedTime(dyddiad, 'Europe/London'));
}

export function gwirioDyddAmser(dydd, amser) {
	let dyddiad = null;
	if (dydd?.match(/^\d\d\d\d-\d\d-\d\d$/)) {
		const m = amser?.match(/^(\d\d):\d\d$/);
		if (m) {
			dyddiad = dyddiadJS(dydd + 'T' + amser);
			if (parseInt(m[1]) < 6) {
				dyddiad = add(dyddiad, {days: 1});
			}
		} else {
			dyddiad = dyddiadJS(dydd + 'T08:00');
			amser = null;
		}
	} else {
		dydd = null;
	}
	return { dyddiad, dydd, amser };
}

export const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function niferGair(nifer, gair, benywaidd = false) {
	const meddal = {c:'g',p:'b',t:'d',g:'',b:'f',d:'dd',ll:'l',rh:'r',m:'f'};
	const llaes = {c:'ch',p:'ph',t:'th'};
	if ( nifer === 2 || (benywaidd && nifer > 0 && nifer < 5) ) {
		gair = gair.replace(/^([cptm](?!h)|d(?!d)|g|b|ll|rh)/i, m => {
			return meddal[m.toLowerCase()];
		});
	}
	else if ( (!benywaidd && nifer === 3) || nifer === 6 ) {
		gair = gair.replace(/^([cpt](?!h))/i, m => {
			return llaes[m.toLowerCase()];
		});
	}
	return nifer + ' ' + gair;
}

export function dechrauSteddfod(blwyddyn) {
	//const dyddiad = new Date(blwyddyn, 7, 1);
	const dyddiad = toDate(`${blwyddyn}-08-01`, {timeZone: 'Europe/London'});
	const dydd = getDay(dyddiad);
	return addDays(dyddiad, (dydd < 2 ? -1 : 6) - dydd);
}

export function heddiwSteddfod(blwyddyn) {
	const heddiw = new Date();
	const dechrau = dechrauSteddfod(blwyddyn);
	let dydd = dechrau;
	for (let i = 1; i < 9; i++) {
		const nesaf = addDays(dydd, 1);
		if (heddiw < nesaf) return dydd;
		dydd = nesaf;
	}
	return dechrau;
}

export function dyddiauSteddfod(blwyddyn) {
	const dechrau = dechrauSteddfod(blwyddyn);
	const dyddiau = [dechrau];
	for (let i = 1; i < 8; i++) {
		dyddiau.push( addDays(dechrau, i) );
	}
	return dyddiau;
}

export function stripHTML(html, keepWhitespace) {
	html = html.replace(/<(script|style)[^>]*?>.*?<\/\\1>/gi, '', html);
	html = html.replace(/<.*?>/g, '', html);
	if (!keepWhitespace) {
		html = html.replace(/\s+/g, ' ', html).trim();
	}
	return html;
}

export function glanhauHTML(html) {
	return html?.replace(/<p>[\xA0\s]*(<[^<>]*>[\xA0\s]*)*<\/p>\n?/gu, '')
		.replace(/[^\S\n]+/gu, ' ')
		.trim() || '';
}

export function glanhauTestun(testun) {
	return testun.replace(/<[^>]*>?/gm, '').replace(/\s*?\n\s*?(\n)?\s*/g, "\n$1").trim();
}

export function glanhauObject(obj) {
	if (typeof obj !== 'object') return obj;
	return Object.fromEntries( Object.entries(obj).map(([name, val]) => {
		if (typeof val === 'string' && !['cynnwys', 'cynnwys_en'].includes(name)) {
			val = glanhauTestun(val);
		}
		return [name, val];
	}) );
}

export function paragraffauHtml(t) {
	t = t?.replaceAll(/[\v\f\r]+/g, "\n").split(/(?: *\n *){2,}/).map(p => '<p>' + p.replaceAll("\n", '<br/>') + "</p>\n").join('');
	return t;
}

export function formDataObject(formData, filterKeys, deep) {
	let data = Object.fromEntries(formData.entries());
	for (const key of Object.keys(data)) {
		if (key.endsWith('[]')) {
			data[key.slice(0, -2)] = formData.getAll(key);
			delete data[key];
		}
	}
	if (deep) data = unflatten(data);
	if (filterKeys) {
		for (const deleteKey of Object.keys(data).filter(key => !filterKeys.includes(key))) {
			delete data[deleteKey];
		}
	}
	return data;
}

export function glanhauMeysydd(data) {
	for (const key of Object.keys(data)) {
		data[key] = glanhauMaes(data[key]);
	}
	return data;
}

function glanhauMaes(v) {
	if (typeof v === 'string') {
		v = v.trim();
		if (v.match(/^\d+$/)) return +v;
	}
	return v;
}

export function gallu(rolau, gwirio, pobUn) {
	if (rolau.includes('gweinyddu') || (typeof gwirio === 'string' && rolau.includes(gwirio))) return true;
	return pobUn ? hasAll(rolau, gwirio) : hasAny(rolau, gwirio);
}

export function hasAll(un, dau) {
	if (!un?.length || !dau?.length) return false;
	for (const a of dau) {
		if (!un.includes(a)) return false;
	}
	return true;
}

export function hasAny(un, dau) {
	if (un?.length && dau?.length) {
		for (const a of un) {
			if (dau.includes(a)) return true;
		}
	}
	return false;
}

const fformatiwrPris = new Intl.NumberFormat('cy-GB', { style: 'currency', currency: 'GBP' });
export function fformatPris(pris) {
	return fformatiwrPris.format(pris).replace(/\.00$/, '');
	//return '£' + pris;
}

export function debounce(func, duration, immediate) {
	let timeout;
	return function (...args) {
		if (immediate && !timeout) {
			func.apply(this, args);
		}
		const effect = () => {
			timeout = null;
			if (!immediate) func.apply(this, args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(effect, duration);
	}
}

// https://dns.google/resolve?name=slebog.net&type=MX
const parthauGwiriwyd = {};
export async function gwirioParthEbost(ebost) {
	const parth = ebost.trimEnd().split('@')[1];
	if (!parth || !parth.includes('.')) return false;
	if (!(parth in parthauGwiriwyd)) {
		let res;
		try {
			res = await timeout(3000, fetch(`https://dns.google/resolve?name=${parth}&type=MX`));
		} catch (e) {
			console.error(e);
		}
		if (res?.ok) {
			const data = await res.json();
			// console.debug('GoogleDNS', data);
			if (data.Status === 0) {
				parthauGwiriwyd[parth] = true;
			} else if (data.Status === 3) {
				parthauGwiriwyd[parth] = false;
			}
		}
	}
	return parthauGwiriwyd[parth] ?? null;
}

export function timeout(ms, promise) {
	return new Promise((resolve, reject) => {
		const timer = setTimeout(() => {
			reject(new Error('TIMEOUT'))
		}, ms)
		promise.then(value => {
			clearTimeout(timer)
			resolve(value)
		})
		.catch(reason => {
			clearTimeout(timer)
			reject(reason)
		})
	});
}

export async function canfodEbostDyblyg(ebost, id, casgliad = 'pobl') {
	ebost = ebost?.trim().toLowerCase();
	if (!ebost) return null;
	const ymholiad = [];
	const gmail = ebost.match(/^(.+@)(gmail|googlemail)\.com$/);
	if (gmail) {
		ymholiad.push( where('ebost', 'in', [
			ebost,
			gmail[1] + (gmail[2] === 'gmail' ? 'googlemail' : 'gmail') + '.com'
		]) );
	} else {
		ymholiad.push( where('ebost', '==', ebost) );
	}
	if (id) {
		ymholiad.push( where(documentId(), '!=', id) );
	}
	return await canfodDogfen(casgliad, ...ymholiad);
}

export function bonyn(t, min = 8, max = 30) {
	const geiriau = t?.trim().normalize('NFKD').toLowerCase()
		.replaceAll(/[^a-z0-9\s\p{Pc}\p{Pd}]+/gv, '') // underscore a dashys
		.split(/[^a-z0-9]+/) || [];
	if (!geiriau.length) return '';
	const unigryw = [...new Set(geiriau)];
	const hydGeiriau = unigryw.map(a => a.length).sort().reverse();
	let bonyn;
	for (const hydGair of hydGeiriau) {
		let newydd = unigryw.filter(a => a.length >= hydGair).join('-');
		if (newydd.length >= min && newydd.length >= max) {
			bonyn ??= newydd.slice(0, max).replace(/-$/, '');
			break;
		}
		bonyn  = newydd;
	}
	return bonyn || unigryw.join('-').slice(0, max).replace(/-$/, '');

/*
llenwi i’r hyd gyda’r geiriau hirach (yn cynnwys rhai byr)
	const hydGeiriau = [...geiriau.map(a => a.length).entries()].sort(
		(a, b) => b[1] - a[1]
	);
	const indecsGeiriau = [];
	let h = 0;
	for (const [i, l] of hydGeiriau) {
		h += h ? l + 1 : l;
		if (h > hyd) break;
		indecsGeiriau.push(i);
	}
	let bonyn = indecsGeiriau.sort().map(i => geiriau[i]).join('-')
		|| geiriau[hydGeiriau[0][0]].slice(0, hyd);
	return bonyn;
*/
/*
	let gairByrraf = 4;
	while (gairByrraf--) {
		/ * esslint-disable-next-line no-loop-func * /
		const hydGeiriau = geiriau.map(a => a.length).sort().reverse();
		for (const gairByrraf of hydGeiriau) {
			bonyn = geiriau.filter(a => a.length >= gairByrraf).reduce((acc, cur) =>
				acc.length + cur.length < hyd
					? acc + '-' + cur
					: acc
			);
			if (bonyn.length >= 8) break;
		}
	bonyn = bonyn.slice(0, 30).replace(/-$/, '');
	return bonyn;
*/
	// const bonyn = t?.normalize('NFKD').toLowerCase()
	// 	.replaceAll(/[^a-z0-9 _—–-]+/gu, '')
	// 	.replaceAll(/[ _-]+/g, '-')
	// 	.replace(/^-/, '')
	// 	.replace(/^(.{6,30})-.*?$/u, '$1')
	// 	.slice(0,30);
}

export async function bonynUnigryw(enw, enwCasgliad) {
	const base = bonyn(enw);
	let unigryw = '';
	let i = 100;
	while (--i) {
		const docSnap = await getDoc( doc(firestore, enwCasgliad, base + unigryw) );
		if (!docSnap.exists()) return base + unigryw;
		unigryw = '-' + Math.random().toString(36).slice(2, 10);
	}
}

export function dyfyniadGeiriau(testun, nodau = 50) {
	testun = testun?.trim();
	if (!testun) return '';
	let byr = '';
	for (const gair of testun.split(/\s+/)) {
		if (byr.length + gair.length + 1 > nodau) {
			break;
		}
		byr += (byr ? ' ' : '') + gair;
	}
	return byr.slice(0, nodau);
}
